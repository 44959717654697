import api from 'src/api/api';

const API_URL =`${process.env.REACT_APP_CURRENT_COMPANY_API_URL}/Company`;

//Get Category Report List
const getCompanyMasterList = async ({
  searchType,
  searchQuery,
  sortType,
  pageNo,
  pageSize,
  Desc,
  dropdown,
  company,
  branch
}) => {
  if ( searchType === 'itemType'){
    searchQuery = dropdown.value;
  }
  const response: any = await api.actionHandler({
    url:(searchType !== ''&& branch !== undefined)
    ? `${API_URL}/?pageNo=${pageNo}&pageSize=${pageSize}&Desc=${Desc}&companyId=${company}&branchId=${branch}&searchType=${searchType}&searchQuery=${searchQuery}&sortType=${sortType}`
    : `${API_URL}/?pageNo=${pageNo}&pageSize=${pageSize}&Desc=${Desc}&companyId=${company}&branchId=${branch}&sortType=${sortType}`,
    method: 'GET'
  });
  return response.data;
};

//Company Master Details By Id
const getCompanyMasterDetailsById = async (id: number): Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: `${API_URL}/${id}`,
      method: 'GET'
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

//Create Company Master
const createCompanyMaster = async(payload:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: API_URL,
      method: 'POST',
      data: payload
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Update Customer Category Master
const updateCompanyMaster = async(payload:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: API_URL,
      method: 'PUT',
      data: payload
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Delete Customer Category Master
const deleteCompanyMaster = async(id:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: `${API_URL}/${id}`,
      method: 'DELETE'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Company Details
const getCurrentCompanyDetails = async (id: any): Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: `${API_URL}/${id}`,
      method: 'GET'
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export {
  getCompanyMasterList,
  getCompanyMasterDetailsById,
  createCompanyMaster,
  updateCompanyMaster,
  deleteCompanyMaster,
  getCurrentCompanyDetails
};
