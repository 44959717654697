import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';


// import { hideScroll } from 'src/theme/css';

import Logo from 'src/components/LogoSign';
import { NavSectionMini } from 'src/components/NavSection';

import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import NavToggleButton from './common/nav-toggle-button';

// ----------------------------------------------------------------------

export default function NavMini() {
  const user = {
    role: 'admin',
  };

  const navData = useNavData();

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 17.5,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          backgroundColor:'white',
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          // ...hideScroll.x,
        }}
      >
        <Box sx={{ my: 2, mx: 'auto' }}>
          <Logo />
        </Box>

        <NavSectionMini
          data={navData}
          slotProps={{
            currentRole: user?.role,
          }}
        />
      </Stack>
    </Box>
  );
};
