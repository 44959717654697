import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';


import { useResponsive } from 'src/hooks/common/use-responsive';

import Logo from 'src/components/LogoSign';
import { NavSectionVertical } from 'src/components/NavSection';
import { NAV } from '../config-layout';

import { useNavData } from './config-navigation';
import NavToggleButton from './common/nav-toggle-button';
import { usePathname } from 'src/hooks/common/use-pathname';

// ----------------------------------------------------------------------

export default function NavVertical({ openNav, onCloseNav }) {
  const user = {
    role: 'admin',
  };

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Box
      className='sidebar'
      sx={{ overflowY: 'auto',height:'93%' }}
    >
      <Box sx={{ my: 2, mx: 'auto' }}>
        <Logo />
      </Box>

      <NavSectionVertical
        data={navData}
        slotProps={{
          currentRole: user?.role,
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

    </Box>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            backgroundColor:'white',
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};
