import { useContext, useMemo } from 'react';
import { accountingIcon, accountReportIcon, dashboardIcon,inventoryReportIcon,mastersIcon,settingsIcon,transactionIcon } from './icons';
import { useTranslate } from 'src/locales';
import { decryptData } from 'src/hooks/common/useEncryption';
import { useQuery } from '@tanstack/react-query';
import { getUserWiseOptions } from 'src/api/settings/options/options';
import { HeaderContext } from 'src/contexts/HeaderContext';

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();
  const { company, branch } = useContext(HeaderContext);

  //Login User Details
  const loginUserId = decryptData('userId');

  const { data: optionsSettings } = useQuery({
    queryKey: ['optionsSettings'],
    queryFn: () => getUserWiseOptions({ company, branch, userId: loginUserId }),
    refetchOnWindowFocus: false,
    enabled: !!company && !!branch
  });

  const isWorkPeriod = optionsSettings?.find((option: any) => option.optionName === 'ActivateWorkPeriodInPOSWeb')?.required;

  const data = useMemo(
    () => [
      // Dashboard
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: t('Menu.Dashboard'),
            path: '/dashboard',
            icon: dashboardIcon,
          },
        ],
      },

      // Masters
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: t('Menu.Masters'),
            icon: mastersIcon,
            children: [
              {
                title:  t('Menu.GeneralMaster'),
                children: [
                  { title: t('Menu.Company'), path: '/masters/company' },
                  { title: t('Menu.BillingLocation'), path: '/masters/branch' },
                  { title: t('Menu.Employee'), path: '/masters/employees' },
                  { title: t('Menu.StockLocation'), path: '/masters/stock-location' },
                  { title: t('Menu.StockLocationCategory'), path: '/masters/stock-location-category' },
                  { title: t('Menu.Series'), path: '/masters/series' },
                  { title: t('Menu.Currency'), path: '/masters/currency' },
                  { title: t('Menu.Country'), path: '/masters/country' },
                  { title: t('Menu.States'), path: '/masters/states' },
                  { title: t('Menu.Areas'), path: '/masters/areas' },
                  { title: t('Menu.CustomerCategoryMaster'), path: '/masters/customer-category' },
                  { title: t('Menu.Vehicle'), path: '/masters/vehicle' },
                  { title: t('Menu.Counters'), path: '/masters/counters' },
                  { title: t('Menu.Bank'), path: '/masters/bank' },
                  { title: t('Menu.StockAdjustmentType'), path: '/masters/stock-adjustment-type' },
                  
                ]
              },
              {
                title: t('Menu.InventoryMaster'),
                children: [
                  { title: t('Menu.Item'), path: '/masters/items' },
                  { title: t('Menu.CategoriesMaster'), path: '/masters/categories' },
                  { title: t('Menu.UnitsMaster'), path: '/masters/units' },
                  { title: t('Menu.PriceList'), path: '/masters/price-list' },
                  { title: t('Menu.SellingPriceUpdation'), path: '/masters/selling-price-updation' },
                ]
              },
              {
                title: t('Menu.AccountsMaster'),
                children: [
                  { title: t('Menu.AccountGroup'), path: '/masters/account-group' },
                  { title: t('Menu.Ledgers'), path: '/masters/ledger-master' },
                  { title: t('Menu.Customer'), path: '/masters/customers' },
                  { title: t('Menu.Vendor'), path: '/masters/vendors' }
                ]
              },
            ],
          },
        ],
      },
      // Transactions
      // -----------------------------------------------------------------------
      {
        items: [
          {
            title: t('Menu.Transactions'),
            icon: transactionIcon,
            children: [
              { title: t('Menu.Purchase'), path: '/inventory/purchase', refetch: true },
              { title: t('Menu.Sale'), path: '/inventory/sale', refetch: true },
              { title: t('Menu.ServiceInvoice'), path: '/inventory/service', refetch: true },
              { title: t('Menu.PurchaseReturn'), path: '/inventory/purchase-return', refetch: true },
              { title: t('Menu.SaleReturn'), path: '/inventory/sales-return', refetch: true },
              { title: t('Menu.Quotation'), path: '/inventory/quotation', refetch: true },
              { title: t('Menu.PurchaseOrder'), path: '/inventory/purchase-order', refetch: true },
              { title: t('Menu.SaleOrder'), path: '/inventory/sale-order', refetch: true },
              { title: t('Menu.DeliveryNote'), path: '/inventory/delivery-note', refetch: true },
              { title: t('Menu.DeliveryReceipt'), path: '/inventory/delivery-note-receipt', refetch: true },
              { title: t('Menu.InternalStockTransfer'), path: '/inventory/internal-stock-transfer', refetch: true },
              { title: t('Menu.StockAdjustment'), path: '/inventory/stock-adjustment', refetch: true },
              { title: t('Menu.POS'), path: isWorkPeriod ? 'work-period' : '/pos' },
            ]
          }
        ]
      },
      // Accounting
      // -----------------------------------------------------------------------
      {
        items: [
          {
            title: t('Menu.Accounting'),
            icon: accountingIcon,
            children: [
              { title: t('Menu.PaymentOut'), path: '/accounts/payment-voucher', refetch: true },
              { title: t('Menu.ReceiptIn'), path: '/accounts/receipt-voucher', refetch: true },
              { title: t('Menu.Journal'), path: '/accounts/journal', refetch: true },
              { title: t('Menu.Contra'), path: '/accounts/contra', refetch: true },
              { title: t('Menu.DebitNote'), path: '/accounts/debit-note', refetch: true },
              { title: t('Menu.CreditNote'), path: '/accounts/credit-note', refetch: true },
              { title: t('Menu.BankReconciliation'), path: '/accounts/bank-reconciliation', refetch: true },
            ]
          }
        ]
      },
      // Inventory Reports
      // -----------------------------------------------------------------------
      {
        items: [
          {
            title: t('Menu.InventoryReports'),
            icon: inventoryReportIcon,
            children: [
              {
                title: t('Menu.PurchaseReports'),
                children: [
                  { title: t('Menu.PurchaseSummary'), path: '/purchase-report' },
                  { title: t('Menu.PurchaseReturnSummary'), path: '/purchase-return-report' },
                  { title: t('Menu.PurchaseOrderSummary'), path: '/purchase-orders' },
                  { title: t('Menu.DeliveryNoteReceiptSummary'), path: '/delivery-note-receipts' },
                  
                ]
              },
              {
                title: t('Menu.SalesReports'),
                children: [
                  { title: t('Menu.SaleSummary'), path: '/sales-report' },
                  { title: t('Menu.SaleReturnSummary'), path: '/sales-return-report' },
                  { title: t('Menu.SaleOrderSummary'), path: '/sale-orders' },
                  { title: t('Menu.QuotationSummary'), path: '/quotation' },
                  { title: t('Menu.DeliveryNoteSummary'), path: '/delivery-notes' },
                ]
              },
              { title: t('Menu.SalesReport(Service)'), path: '/service-report' },
              {
                title: t('Menu.StockReports'),
                children: [
                  { title: t('Menu.StockReport'), path: '/stocks' },
                  { title: t('Menu.StockRegisterReport'), path: '/detailed-stock-report' },
                  { title: t('Menu.OpeningStockReport'), path: '/opening-stock-report' },
                  { title: t('Menu.StockAdjustments'), path: '/stock-adjustment-report' },
                  { title: t('Menu.InternalStockTransferReport'), path: '/internal-stock-transfers' },
                  { title: t('Menu.ItemWiseStockRegister'), path: '/item-wise-stock-report' },
                ]
              },
              { title: t('Menu.BillWiseMargin'), path: '/bill-wise-margin' },
              { title: t('Menu.ItemWiseMargin'), path: '/item-wise-margin' },
              { title: t('Menu.ItemWiseTransactions'), path: '/item-wise-transactions' },
              { title: t('Menu.ZReport'), path: '/overall-z-report' },
              { title: t('Menu.AdjustmentReport'), path: '/adjustment-report' },
              { title: t('Menu.CancelledTransactions'), path: '/cancelled-transactions' },
              { title: t('Menu.SerialNo/IMEINoTransactions'), path: '/serial-no-transactions' },
              { title: t('Menu.SalesReportDetailed'), path: '/sales-report-detailed' },
              { title: t('Menu.MonthlySalesAnalysis'), path: '/monthly-sales-analysis' },
              { title: t('Menu.MonthlyPurchaseAnalysis'), path: '/monthly-purchase-analysis' },
              { title: t('Menu.DailySalesReport'), path: '/daily-sales-report' },
              { title: t('Menu.DailyPurchaseReport'), path: '/daily-purchase-report' },
              { title: t('Menu.ItemExpiryReport'), path: '/item-expiry-report' },
              { title: t('Menu.ServiceExpiryReport'), path: '/service-expiry-report' },
              { title: t('Menu.ReorderLevelReport'), path: '/reorder-level-report' },
              { title: t('Menu.MinimumStockLevelReport'), path: '/minimum-stock-level-report' },
              { title: t('Menu.MaximumStockLevelReport'), path: '/maximum-stock-level-report' },
              { title: t('Menu.ForexTransactionsReport'), path: '/forex-transactions-report' },
              { title: t('Menu.ItemWiseSalesOrderSummary'), path: '/item-wise-sales-order-summary'},
            ]
          }
        ]
      },
      // Account Reports
      // -----------------------------------------------------------------------
      {
        items: [
          {
            title: t('Menu.AccountsReports'),
            icon: accountReportIcon,
            children: [
              { title: t('Menu.DebtorsReport'), path: '/accounts/debtors-report' },
              { title: t('Menu.AgeWiseDebtorsReport'), path: '/accounts/age-wise-debtors-report' },
              { title: t('Menu.CreditorsReport'), path: '/accounts/creditors-report' },
              { title: t('Menu.AgeWiseCreditorsReport'), path: '/accounts/age-wise-creditors-report' },
              { title: t('Menu.Ledger'), path: '/ledger' },
              { title: t('Menu.VoucherReport'), path: '/voucher-report' },
              {
                title: t('Menu.TaxReports'),
                children: [
                  { title: t('Menu.InputTax'), path: '/input-tax' },
                  { title: t('Menu.OutputTax'), path: '/output-tax' },
                  { title: t('Menu.VATSummary'), path: '/vat-summary' },
                ]
              },
              {
                title: t('Menu.FinalAccounts'),
                children: [
                  { title: t('Menu.ProfitAndLoss'), path: '/profit-loss' },
                  { title: t('Menu.BalanceSheet'), path: '/accounts/balance-sheet' },
                  { title: t('Menu.TrialBalance'), path: '/accounts/trial-balance' },
                ]
              },
              { title: t('Menu.BillWisePendingSales'), path: '/pending-bill-report' },
              { title: t('Menu.BillWisePendingPurchase'), path: '/bill-wise-pending-purchase' },
              { title: t('Menu.PDCReport'), path: '/accounts/pdc-report' },
              { title: t('Menu.DayBook'), path: '/daybooks' },
              { title: t('Menu.CashBook'), path: '/cashbook' },
              { title: t('Menu.BankBook'), path: '/bankbook' },
              { title: t('Menu.TransactionSummary'), path: '/accounts/transaction-summary' },
              { title: t('Menu.Salesman/ExecutiveWisePaymentCollectionReport'), path: '/salesman-wise-payment-collection-report' },
              { title: t('Menu.OverDueSalesInvoiceReport'), path: '/overdue-sales-invoice-report' },
              { title: t('Menu.OverDuePurchaseInvoiceReport'), path: 'overdue-purchase-invoice-report' },
              { title: t('Menu.PdcCalendar'), path: '/pdc-calendar' },
              { title: t('Menu.ChartOfAccounts'), path: '/chart-of-accounts' },
            ]
          }
        ]
      },
      // Settings
      //-----------------------------------------------------------------------
      {
        items: [
          {
            title: t('Menu.Settings'),
            icon: settingsIcon,
            children: [
              { title: t('Menu.UserManagement'), path: '/user-management' },
              { title: t('Menu.UserRole'), path: '/user-roles' },
              { title: t('Menu.Print'), path: '/printsettings' },
              { title: t('Menu.Dashboard'), path: '/dashboardsettings' },
              { title: t('Menu.Options'), path: '/options' },
          
            ]

          }
        ]
      }
    ],
    [t]
  );

  return data;
};
