import { useTranslation } from 'react-i18next';


import { useSettingsContext } from 'src/contexts/SettingContext';

import { allLangs, defaultLang } from './config-lang';

// ----------------------------------------------------------------------

export function useLocales() {
  const langStorage = localStorage.getItem('i18nextLng');

  const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  return {
    allLangs,
    currentLang,
  };
}

// ----------------------------------------------------------------------

export function useTranslate() {
  const { t, i18n, ready } = useTranslation();

  const settings = useSettingsContext();

  const onChangeLang = (newlang) => {
    if (newlang) {
      i18n.changeLanguage(newlang);
      settings.onChangeDirectionByLang(newlang);
    }
  };

  return {
    t,
    i18n,
    ready,
    onChangeLang,
  };
}
