import api from '../api';
const ADMIN_API_URL = `${process.env.REACT_APP_ADMIN_URL}`;

const getSubscriptionPlans = async () => {
  try {
    const response: any = await api.actionHandler({
      url: `${ADMIN_API_URL}Plan?platForm=WEB`,
      method: 'GET'
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

const getCustomerCountries = async () => {
  try {
    const response: any = await api.actionHandler({
      url: `${ADMIN_API_URL}Country`,
      method: 'GET'
    });
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

const getCustomerCurrencies = async () => {
  try {
    const response: any = await api.actionHandler({
      url: `${ADMIN_API_URL}Currency`,
      method: 'GET'
    });
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

const initializePayment = async(payload:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: `${ADMIN_API_URL}Payment`,
      method: 'POST',
      data: payload
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

const completePayment = async(payload:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: `${ADMIN_API_URL}Payment`,
      method: 'PUT',
      data: payload
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

const getUserSubscriptionDetails = async (userId) => {
  try {
    const response: any = await api.actionHandler({
      url: `${ADMIN_API_URL}User/UserDetails/${userId}`,
      method: 'GET'
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export { getSubscriptionPlans , getCustomerCountries, getCustomerCurrencies ,initializePayment ,completePayment ,getUserSubscriptionDetails};
