import { Box, Link, Typography, styled } from '@mui/material';

const FooterWrapper = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
        
        
`
);

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={2}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="end"
        px={{ xs: 3, md: 3 }}
      >
        <Box>
          <Typography variant="subtitle1">
            &copy; {currentYear} -
            <Link
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              Techrope Technologies
            </Link>
          </Typography>
        </Box>

      </Box>
    </FooterWrapper>
  );
}

export default Footer;
