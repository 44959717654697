import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { useResponsive } from 'src/hooks/common/use-responsive.js';

import { useSettingsContext } from 'src/contexts/SettingContext/index.js';

import { NAV, HEADER } from '../config-layout.js';

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, ...other }) {
  const settings: any = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const isNavMini = settings.themeLayout === 'mini';

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        maxHeight:'100%',
        display: 'flex',
        flexDirection: 'column',
        ...(lgUp && {
          px: 1.2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...(!lgUp && {
          mt: 8,
          px:1
        })
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
