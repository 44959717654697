import api from 'src/api/api'

const API = `${process.env.REACT_APP_CURRENT_COMPANY_API_URL}/Options`;

//Create User 
const updateOptions = async(payload:any):Promise<any> => {
    try {
      const response: any = await api.actionHandler({
        url: API,
        method: 'POST',
        data: payload
      });
      return response?.data;
    } catch (error) {
        throw error;
    }
  }

  const getOptionsSettings = async(company : string , branch : string):Promise<any> => {
    try {
      const response: any = await api.actionHandler({
        url: `${API}?companyId=${company}&branchId=${branch}`,
        method: 'GET'
      });
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  const getUserWiseOptions = async({company , branch , userId }) => {
    try {
      const response: any = await api.actionHandler({
        url: `${API}?companyId=${company}&branchId=${branch}&userId=${userId}`,
        method: 'GET'
      });
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }

  export {
    updateOptions,
    getOptionsSettings,
    getUserWiseOptions
  };
  