//@ts-nocheck
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:alpha(theme.palette.primary.main, 0.16),
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '6px 10px',
    borderRadius:'8px',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight:400
  },
}));

export const CustomIconTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    padding: '3px 10px',
    borderRadius: '6px',
    fontSize: 11,
    fontWeight: 400
  }
}));