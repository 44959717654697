import api from '../api';
import Swal from 'sweetalert2';
import { enqueueSnackbar } from 'notistack';
import { decryptData } from 'src/hooks/common/useEncryption';

const COMPANY_API_URL = `${process.env.REACT_APP_CURRENT_COMPANY_API_URL}`;

// Permission Details
const getUserPermissionDetails = async ({ company, branch }) => {
  const userId = decryptData('userId')
  const userRoleId = decryptData('userRoleId')

  const response = await api.actionHandler({
    url: `${COMPANY_API_URL}/Permissions?searchType=userPermissions&userId=${userId}&userRoleId=${userRoleId}&companyId=${company}&branchId=${branch}`,
    method: 'GET',
  });
  //@ts-ignore
  return response?.data?.data;
};

// Permission Details
const getPermissionDetails = async () => {
  const userId = decryptData('userId')
  const userRoleId = decryptData('userRoleId')

  const response = await api.actionHandler({
    url: `${COMPANY_API_URL}/Permissions?searchType=permissions&userId=${userId}&userRoleId=${userRoleId}`,
    method: 'GET',
  });
  //@ts-ignore
  return response?.data?.data;
};

// Check Permission Details
const checkPermission = async (systemName: string, handleAction = null) => {
  try {
    const permissionDetails = await getPermissionDetails();
    const { userRole } = permissionDetails || {};
    const { permissions, isSystemRole } = userRole || {};
    const hasPermission = isSystemRole || permissions?.some(permission => permission.systemName === systemName && permission.status);

    
    if (hasPermission) {
      if (handleAction) {
        handleAction();
      }
      return true;
    }

    const message = 'Access Denied';

    if (handleAction) {
      enqueueSnackbar(message, { variant: 'error' });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: message
      });
    }
    return false;
    
  } catch (error) {
    console.log(error);
    return false;
  }
}

const getUserPermissionStatus = async (systemName: string): Promise<boolean> => {
  try {
    const permissionDetails = await getPermissionDetails();
    const { userRole } = permissionDetails || {};
    const { permissions, isSystemRole } = userRole || {};

    return isSystemRole || permissions?.some(permission => permission.systemName === systemName && permission.status) || false;
  } catch (error) {
    console.error('Error fetching user permission status:', error);
    return false;
  }
};


export { getPermissionDetails, checkPermission, getUserPermissionDetails, getUserPermissionStatus };
