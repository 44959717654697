import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import { useResponsive } from 'src/hooks/common/use-responsive';

import { useSettingsContext } from 'src/contexts/SettingContext';


import { NAV } from '../../config-layout';
import Iconify from 'src/components/Iconify';
import { CustomTooltip } from 'src/components/Custom/CustomTooltip';

// ----------------------------------------------------------------------


export default function NavToggleButton({ sx, ...other }) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  if (!lgUp) {
    return null;
  }

  return (
    <CustomTooltip title={settings.themeLayout === 'vertical' ? 'Collapse' : 'Expand'}>
      <IconButton
        size="small"
        onClick={() =>
          settings.onUpdate('themeLayout', settings.themeLayout === 'vertical' ? 'mini' : 'vertical')
        
        }
        sx={{
          p: 0.5,
          top: 16.5,
          position: 'fixed',
          left: NAV.W_VERTICAL - 12,
          zIndex: theme.zIndex.appBar + 1,
          border: `1px solid ${theme.palette.divider}`,
          color: 'gray',
          '&:hover': {
            bgcolor: 'white',
          },
          ...sx,
        }}
        {...other}
      >
        <Iconify
          width={16}
          icon={
            settings.themeLayout === 'vertical'
              ? 'eva:arrow-ios-back-fill'
              : 'eva:arrow-ios-forward-fill'
          }
        />
      </IconButton>
    </CustomTooltip>
  );
}

NavToggleButton.propTypes = {
  sx: PropTypes.object,
};
