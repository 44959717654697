/* src/components/LoadingScreen.jsx */
import './loadingScreen.css';

function Loading() {
  // const { color, size, thickness } = props;
  return (
    <div className="loading-screen">
      <svg viewBox="-2000 -1000 4000 2000">
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#03944A" />
            <stop offset="50%" stopColor="#12816B" />
            <stop offset="100%" stopColor="#206D8B" />
          </linearGradient>
        </defs>
        <path
          id="stroke"
          d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"
        ></path>
        <use
          xlinkHref="#stroke"
          stroke="url(#gradient)"
          strokeDasharray="1570 5143"
          strokeDashoffset="6713px"
        ></use>
        
      </svg>
      <p>Loading...</p>
    </div>
  );
}

export default Loading;
