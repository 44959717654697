import api from 'src/api/api';

const API_URL =`${process.env.REACT_APP_CURRENT_COMPANY_API_URL}/Country`;

//Get Category Report List
const getCountryMasterList = async ({
  searchType,
  searchQuery,
  sortType,
  pageNo,
  pageSize,
  Desc,
  dropdown,
  company,
  branch
}) => {
  if ( searchType === 'itemType'){
    searchQuery = dropdown.value;
  }
  const response: any = await api.actionHandler({
    url:(searchType !== ''&& branch !== undefined)
    ? `${API_URL}/?pageNo=${pageNo}&pageSize=${pageSize}&Desc=${Desc}&companyId=${company}&branchId=${branch}&searchType=${searchType}&searchQuery=${searchQuery}&sortType=${sortType}`
    : `${API_URL}/?pageNo=${pageNo}&pageSize=${pageSize}&Desc=${Desc}&companyId=${company}&branchId=${branch}&sortType=${sortType}`,
    method: 'GET'
  });
  return response.data;
};

//Country Master Details By Id
const getCountryMasterDetailsById = async (id: number): Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: `${API_URL}/${id}`,
      method: 'GET'
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

//Create Country Master
const createCountryMaster = async(payload:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: API_URL,
      method: 'POST',
      data: payload
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Update Customer Category Master
const updateCountryMaster = async(payload:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: API_URL,
      method: 'PUT',
      data: payload
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Delete Customer Category Master
const deleteCountryMaster = async(id:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: `${API_URL}/${id}`,
      method: 'DELETE'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//country List
const getCountryList = async () => {
  try {
    const response: any = await api.actionHandler({
      url: API_URL ,
      method: 'GET'
    });
    let countryData = response.data.data;
    return countryData;
  } catch (error) {
    throw error;
  }
};

export {
  getCountryMasterList,
  getCountryMasterDetailsById,
  createCountryMaster,
  updateCountryMaster,
  deleteCountryMaster,
  getCountryList
};
