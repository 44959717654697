
import { styled } from '@mui/system';
import Select from '@mui/material/Select';
import { memo } from 'react';

const FormikSelect = styled(Select)({
  height: '37px',
  borderRadius: '6px',
});

export default memo(FormikSelect);
