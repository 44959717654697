//Custom formik text field

import { TextField } from 'formik-mui';
import { styled } from '@mui/material';
import { memo } from 'react';

const CustomFormikTextField = styled(TextField)({
  width: '100%',
  borderRadius: '6px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D0D0D0'
    },
    '&:hover fieldset': {
      borderColor: '#D0D0D0'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D0D0D0'
    }
  }
});

export default memo(CustomFormikTextField);
