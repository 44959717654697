
import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_ENCRYPTION_KEY||'mysalebooks-secret-key';

const encryptAndStoreData = (key: string, data: string) => {
  const cipherText = CryptoJS.AES.encrypt(data, secretKey).toString();
  localStorage.setItem(key, cipherText);
};

const decryptData = (key: string): string => {
  const encrypted = localStorage.getItem(key);
  if (!encrypted) return '';
  const bytes = CryptoJS.AES.decrypt(encrypted, secretKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
};

export { encryptAndStoreData, decryptData };