import api from 'src/api/api';

const API_URL =`${process.env.REACT_APP_CURRENT_COMPANY_API_URL}/Currency`;
const API_URL1 = `${process.env.REACT_APP_API_URL}Currency`;

//Get Item Report List
const getCurrencyMasterList = async ({
  searchType,
  searchQuery,
  sortType,
  pageNo,
  pageSize,
  Desc,
  dropdown,
  company,
  branch
}) => {
  if (searchType === 'itemType') {
    searchQuery = dropdown.value;
  }
  const response: any = await api.actionHandler({
    url:( searchType !== '' && branch !== undefined)
        ? `${API_URL1}/?pageNo=${pageNo}&pageSize=${pageSize}&Desc=${Desc}&companyId=${company}&branchId=${branch}&searchType=${searchType}&searchQuery=${searchQuery}&sortType=${sortType}`
        : `${API_URL1}/?pageNo=${pageNo}&pageSize=${pageSize}&Desc=${Desc}&companyId=${company}&branchId=${branch}&sortType=${sortType}`,
    method: 'GET'
  });
  return response.data;
};


//Currency Master Details By Id
const getCurrencyMasterDetailsById = async (id: number): Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: `${API_URL}/${id}`,
      method: 'GET'
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

//Create Currency Master
const createCurrencyMaster = async(payload:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: API_URL,
      method: 'POST',
      data: payload
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Update Currency Master
const updateCurrencyMaster = async(payload:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: API_URL,
      method: 'PUT',
      data: payload
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Delete Currency Master
const deleteCurrencyMaster = async(id:any):Promise<any> => {
  try {
    const response: any = await api.actionHandler({
      url: `${API_URL}/${id}`,
      method: 'DELETE'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//currency List
const getCurrencyList = async () => {
  try {
    const response: any = await api.actionHandler({
      url: API_URL,
      method: 'GET'
    });
    let currencyData = response.data.data;
    return currencyData;
  } catch (error) {
    throw error;
  }
};
export {
  getCurrencyMasterList,
  createCurrencyMaster,
  getCurrencyMasterDetailsById,
  updateCurrencyMaster,
  deleteCurrencyMaster,
  getCurrencyList
};

