import PropTypes from 'prop-types';
import { FC } from 'react'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// ----------------------------------------------------------------------

const LocalizationProvider:FC = ({ children })=> {

  return (
    <MuiLocalizationProvider>
      {children}
    </MuiLocalizationProvider>
  );
}

LocalizationProvider.propTypes = {
  children: PropTypes.node,
};

export default LocalizationProvider;
