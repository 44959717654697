import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useTheme } from '@mui/material/styles';

import { useOffSetTop } from 'src/hooks/common/use-off-set-top';
import { useResponsive } from 'src/hooks/common/use-responsive';

import { NAV, HEADER, bgBlur } from '../config-layout';
import { useSettingsContext } from 'src/contexts/SettingContext';
import LanguageSelector from './common/LanguageSelector';
import HeaderAccount from './common/HeaderAccount';
import HeaderOptions from './common/HeaderOptions';
import { IconButton, Typography } from '@mui/material';
import { menuItemIcon } from './icons';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav, ...props }) {
  const { companyName } = props;
  const location = useLocation(); 

  const theme = useTheme();

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  //@ts-ignore
  const isNavMini = settings.themeLayout === 'mini';

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset;

  const renderContent = (
    <>
      {lgUp && <Typography color='primary' variant='h5' sx={{ ml: 3, fontSize: 16 }}>{companyName || '--'}</Typography>}
      {!lgUp && (
        <IconButton sx={{ ml: 1.5 }} onClick={onOpenNav}>
          {menuItemIcon}
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
        mr={2.5}
      >
        <HeaderOptions />
        <LanguageSelector />
        <HeaderAccount />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.paper,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
