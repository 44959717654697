import { useContext, useEffect } from 'react';
import { Box, InputLabel, Select, FormControl, MenuItem, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  getCompanyList,
  getBranchList
} from 'src/api/common/dropdownList';
import { useLocation } from 'react-router';
import { ICompany, IBranch } from 'src/lib/interfaces/IGeneralReport';
import { HeaderContext } from 'src/contexts/HeaderContext';
import { useResponsive } from 'src/hooks/common/use-responsive';
import { getPermissionDetails } from 'src/api/permissions/userPermissions';
import { t } from 'i18next';

function HeaderSearch() {
  const { company, setCompany, branch, setBranch, setPermissionDetails } = useContext(HeaderContext);

  const lgUp = useResponsive('up', 'lg');

  //Get Company List
  const { data: companyList } = useQuery<ICompany[]>({
    queryKey: ['companyList'],
    queryFn: () => getCompanyList()
  });

  const location = useLocation();
  const branchId = localStorage.getItem('branchId');

  useEffect(() => {
    if (branchId) {
      setBranch(branchId);
    }
  }, [branchId]);

  useEffect(() => {
    if (companyList) {
      setCompany(companyList[0]?.id);
    }
  }, [companyList]);

  //Get Branch List
  const { data: branchList, isPending: branchLoading } = useQuery<IBranch[]>({
    queryKey: ['branchList', company],
    queryFn: () => getBranchList(company),
    enabled: !!company
  });

  const { data: permissionDetails, isPending: permissionLoading } = useQuery<any>({
    queryKey: ['permissionDetails', company],
    queryFn: () => getPermissionDetails(),
    refetchOnWindowFocus: false,
    enabled: !!company
  });

  useEffect(() => {
    if (permissionDetails) {
      setPermissionDetails(permissionDetails || []);
    } else if (permissionDetails && permissionDetails?.userBranchMappings?.length > 0) {
      setBranch(permissionDetails?.userBranchMappings[0]?.branchId)
    }
    return () => {
      setBranch((branch) => branch); // Reset branch to its initial value
    };
  }, [permissionDetails]);

  const isNotSpecialPath = !['/profit-loss', '/accounts/balance-sheet', '/accounts/trial-balance'].includes(location.pathname);

  
  return (
    <Box>
      {/* <FormControl sx={{ minWidth: 230, mx: 2,transition: 'all .3s ease-in-out'  }}></FormControl> */}
      {/* {<FormControl sx={{ minWidth: 230 ,transition: 'all .3s ease-in-out'  }}>
          {companyLoading?<CircularProgress size={35}/>:<><InputLabel id="company-select-label">{t("Common.Company")}</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            size="small"
            value={company}
            label={t("Common.Company")}
            onChange={(e) => setCompany(e.target.value)}
            disabled
          >
            <MenuItem value="" disabled>Choose</MenuItem>
            {companyList?.map((company, idx) => (
              <MenuItem key={idx} value={company.id}>
                {company.companyName}
              </MenuItem>
            ))}
          </Select></>}
        </FormControl> } */}
      {isNotSpecialPath && <>{lgUp && (permissionDetails?.userBranchMappings?.length > 0 ? (
        <FormControl
          sx={{ minWidth: 230, mx: 1 }}
        >
          {permissionLoading ? <CircularProgress size={35} /> : <><InputLabel id="branch-select-label">{t("Common.BillingLocation")}</InputLabel>
            <Select
              labelId="branch-select-label"
              id="branch-select"
              size="small"
              value={branch}
              label={t("Common.BillingLocation")}
              onChange={(e) => {
                setBranch(e.target.value)
                localStorage.setItem('branchId', e.target.value)
              }}
              disabled={(location?.pathname?.includes('/inventory') || location?.pathname?.includes('/masters') || location?.pathname?.includes('/accounts')) && branch != '0'}
            >
              <MenuItem value="" disabled>Choose</MenuItem>
              {permissionDetails?.userBranchMappings?.map((branch, idx) => (
                <MenuItem key={idx} value={branch.branchId}>
                  {branchList?.find((item) => item.id === branch.branchId)?.branchName}
                </MenuItem>
              ))}
            </Select></>}
        </FormControl>
      ) : (
        <FormControl
          sx={{ minWidth: 230, mx: 1 }}
        >
          {branchLoading ? <CircularProgress size={35} /> : <><InputLabel id="branch-select-label">{t("Common.BillingLocation")}</InputLabel>
            <Select
              labelId="branch-select-label"
              id="branch-select"
              size="small"
              value={branch}
              label={t("Common.BillingLocation")}
              sx={{ borderRadius: '6px' }}
              onChange={(e) => {
                setBranch(e.target.value)
                localStorage.setItem('branchId', e.target.value)
              }}
              disabled={(location?.pathname?.includes('/inventory') || location?.pathname?.includes('/masters') || location?.pathname?.includes('/accounts')) && branch != '0'}
            >
              <MenuItem value="" disabled>{t("Common.Choose")}</MenuItem>
              {branchList?.length > 1 && <MenuItem value="0">{t("Common.All")}</MenuItem>}
              {branchList?.map((branch, idx) => (
                <MenuItem key={idx} value={branch.id}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select></>}
        </FormControl>
      ))}</>}
    </Box>
  );
};

export default HeaderSearch;
