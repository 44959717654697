import { FC, useState,createContext } from 'react';

type FilterContext = {
  reportFilters: any;
  setFilterForReport: any;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterContext = createContext<FilterContext>({} as FilterContext);

export const FilterProvider: FC = ({ children }) => {
  const [reportFilters, setReportFilters] = useState(() => {
    const savedFilters = sessionStorage.getItem('reportFilterData');
    return savedFilters ? JSON.parse(savedFilters) : {};
  });

  const setFilterForReport = (reportName, filterValues) => {
    setReportFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [reportName]: {
          ...prevFilters[reportName],
          ...filterValues,
        },
      };
      sessionStorage.setItem('reportFilterData', JSON.stringify(updatedFilters)); // Save to sessionStorage
      return updatedFilters;
    });
  };

  return (
    <FilterContext.Provider value={{ reportFilters, setFilterForReport }}>
      {children}
    </FilterContext.Provider>
  );
};

