import { useState, useEffect } from 'react';
import {
  SwipeableDrawer,
  Grid,
  Typography,
  IconButton,
  Button,
  MenuItem,
  FormHelperText

} from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

//React Query
import { updateCompanyMaster, getCompanyMasterDetailsById } from 'src/api/masters/companyMaster/company';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getCurrencyList } from 'src/api/masters/currencyMaster/currency';
import { getCountryList } from 'src/api/masters/country/country';

//Formik Imports
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikInputWithLabel from 'src/components/Custom/FormikInputWithLabel';
import CustomFormikTextField from 'src/components/Custom/FomikTextField';
import { useSnackbar } from 'notistack';
import FormikSelect from 'src/components/Custom/FormikSelect';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { decryptData } from 'src/hooks/common/useEncryption';
import { convertDateWithTime } from 'src/utils/common/util';

interface Props {
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
  companyID: number;
}

function CompanyUpdateModal({ toggle, setToggle, companyID }: Props) {
  // const { company, branch } = useContext(HeaderContext);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const loginUserId = decryptData('userId');
  const [previewLogo, setPreviewLogo] = useState(null);

  //Work Period Details
  const counterId = localStorage.getItem('counterId');
  const workPeriodId = localStorage.getItem('workPeriodId');
  //Current Date
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  const currentDate = yyyy + '-' + mm + '-' + dd;
  
  //Initial Values
  let [initialValues, setInitialValues] = useState({
    companyName: '',
    companyLocalName:'',
    phoneNo: '',
    customName: '',
    address: '',
    address1: '',
    email: '',
    licenseNo: '',
    taxNo:'',
    costingType: 'AVG',
    isTaxApplicable: 'tax_registered',
    website:'',
    countryId:'',
    currencyId:'',
    financialYearFrom: currentDate,
    financialYearTo: currentDate,
    isCurrentCompany: true,
    logo:'',
  });

  //Validation Schema
  const [validationSchema] = useState({
    companyName: Yup.string().required('Required'),
    countryId: Yup.string().required('Required'),
    currencyId: Yup.string().required('Required'),
  });

  //Company Create Mutation
  const { mutate: updateCompany, isPending: editLoading } = useMutation({
    mutationFn: updateCompanyMaster,
    onSuccess: () => {
      enqueueSnackbar(t("CompanyMaster.Updated"), { variant: 'success' });
      setToggle(false);
    },
    onError: (error) => {
      //@ts-ignore
      if (error.response) {
        //@ts-ignore
        const errorMessage = error.response.data;
        enqueueSnackbar(`Cannot Update! ${errorMessage}`, { variant: 'error' });
      } else {
        //@ts-ignore
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    onSettled: () => {
      //@ts-ignore
      queryClient.invalidateQueries('companyMasterList');
    }
  });

  //Company Master Details By Id
  const { data: companyMasterDetails } = useQuery<any>({
    queryKey: ['companyMasterDetails', companyID],
    queryFn: () => getCompanyMasterDetailsById(companyID),
    refetchOnWindowFocus: false,
    enabled: companyID !== null
  });

  //country List
  const { data: countryList } = useQuery<any>({
    queryKey: ['countryList'],
    queryFn: () => getCountryList(),
    refetchOnWindowFocus: false
  });

    //country List
  const { data: currencyList } = useQuery<any>({
    queryKey: ['currencyList'],
    queryFn: () => getCurrencyList(),
    refetchOnWindowFocus: false
  });

  const convertDateToYMD = (date) => {
    if(!date) return;
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const monthString = month < 10 ? `0${month}` : `${month}`;
    const dayString = day < 10 ? `0${day}` : `${day}`;
    return `${year}-${monthString}-${dayString}`;
  }
  //Set Initial Values
  useEffect(() => {
    if (companyMasterDetails) {
      setInitialValues({
        companyName: companyMasterDetails?.companyName,
        companyLocalName: companyMasterDetails?.companyLocalName,
        phoneNo: companyMasterDetails?.phoneNo,
        customName: companyMasterDetails?.customName,
        address: companyMasterDetails?.address,
        address1: companyMasterDetails?.address1,
        email: companyMasterDetails?.email,
        website: companyMasterDetails?.website,
        countryId: companyMasterDetails?.countryId,
        currencyId: companyMasterDetails?.currencyId,
        licenseNo: companyMasterDetails?.licenseNo,
        taxNo: companyMasterDetails?.taxNo,
        costingType: companyMasterDetails?.costingType,
        isTaxApplicable: companyMasterDetails?.isTaxApplicable ? 'tax_registered' : 'tax_non_registered',
        financialYearFrom: convertDateToYMD(companyMasterDetails?.financialYearFrom),
        financialYearTo: convertDateToYMD(companyMasterDetails?.financialYearTo),
        isCurrentCompany: companyMasterDetails?.isCurrentCompany,
        logo: companyMasterDetails?.logo,
      });
      if (companyMasterDetails.logo) {
        setPreviewLogo(`data:image/png;base64, ${companyMasterDetails.logo}`);
      }
      else{
        setPreviewLogo(null);
      }
    }
  }, [companyMasterDetails]);

  //Updated By Nidheesh on 02/Sep/2024
  const handleSubmit = async (values, setSubmitting, resetForm) => {
    const payload = {
      ...values,
      id: companyID,
      // companyId: company,
      // branchId: branch,
      allowsBilling: true,
      allowsShipping: true,
      isApproved: true,
      isCanceled: false,
      isDefault: false,
      published: true,
      isDeleted: false,
      isBasicRegistration:true,
      displayOrder: 0,
      isTaxApplicable: values.isTaxApplicable === 'tax_registered' ? true : false,
      status: true,
      platForm: 'WEB',
      createdBy: companyMasterDetails?.createdBy,
      createdDate: convertDateWithTime(companyMasterDetails?.createdDate),
      updatedBy: loginUserId,
      updatedDate: convertDateWithTime(currentDate),
      userId: loginUserId,
      counterId: counterId || '0',
      workPeriodId: workPeriodId || '0',
    };
    try {
      await updateCompany(payload);
      resetForm();
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    //@ts-ignore
    <SwipeableDrawer
      ModalProps={{
        onBackdropClick: () => setToggle(false)
      }}
      anchor="right"
      open={toggle}
      sx={{
        '& .MuiDrawer-paper': {
          width: '40%',
          //for mobile
          '@media (max-width: 600px)': {
            width: '100%'
          },
          //for tablet
          '@media (min-width: 600px) and (max-width: 960px)': {
            width: '70%'
          },
          padding: '2rem',
          borderRadius: '0 0 0 1rem',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          height: '100vh',
          backgroundColor: '#fff'
        }
      }}
      onClose={() => setToggle(false)}
    >
      <Box sx={{ p: 2, position: 'relative' }}>
        <Grid
          container
          sx={{
            width: '40%',
            //for mobile
            '@media (max-width: 600px)': {
              width: '100%'
            },
            //for tablet
            '@media (min-width: 600px) and (max-width: 960px)': {
              width: '70%'
            },
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'fixed',
            top: '0',
            right: '0',
            px:5.5,
            background:'#223354',
            zIndex: '1',
            height:70

          }}
        >
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                fontSize: '18px',
                fontWeight: '800',
                color: '#fff'
              }}
            >
              {t("CompanyMaster.UpdateCompany")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => setToggle(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={Yup.object(validationSchema)}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, setSubmitting, resetForm);
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <Grid
                container
                spacing={2}
                sx={{ marginTop: '1rem', marginBottom: '2rem', rowGap: '0.2rem' }}
              >
                <Grid item xs={12} sm={6}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.CompanyLogo")}
                  </label><br/>                   
                  <input
                    id="image-upload"
                    type="file"
                    accept="image/*"
                    name="logo"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      if (file && file.size > 250000) {
                        enqueueSnackbar(t("Common.ImageSizeShouldBeBelow250KB"), { variant: 'warning' });
                        return;
                      }
                      const reader = new FileReader();

                      reader.onload = () => {
                        //@ts-ignore
                        const base64String = reader.result.split(',')[1];
                        setPreviewLogo(reader.result);
                        setFieldValue('logo', base64String);
                      };

                      reader.readAsDataURL(file);
                    }}
                    style={{ display: 'none' }}
                  />
                  <Button
                    component="span"
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ width:'150px',marginTop:'1rem', marginBottom:'1rem' }}
                    onClick={() => {
                      document.getElementById('image-upload').click();
                    }}
                  >
                    <CloudUploadIcon sx={{ marginRight: '8px' }} />
                    {t("CompanyMaster.ChooseFile")}
                  </Button>

                  {previewLogo && (
                    <div style={{ marginTop: '0.5rem', position: 'relative' }}>
                      <img src={previewLogo} alt="Preview Image" style={{ width: '100%', height: 'auto' }} />
                      <IconButton
                        color="secondary"
                        aria-label="Remove Image"
                        onClick={() => {
                          setPreviewLogo(null);
                          setFieldValue('logo', null);
                        }}
                        style={{ position: 'absolute', top: '5px', right: '5px', backgroundColor: 'white', color: 'black', padding: '3px', borderRadius: '50%' }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  )}
                                   
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.CompanyName")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="companyName"
                    type="text"
                    placeholder={t("CompanyMaster.CompanyName")}
                    component={FormikInputWithLabel}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.CompanyLocalName")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="companyLocalName"
                    type="text"
                    placeholder={t("CompanyMaster.CompanyLocalName")}
                    component={FormikInputWithLabel}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.PhoneNumber")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="phoneNo"
                    type="text"
                    placeholder={t("CompanyMaster.PhoneNumber")}
                    component={FormikInputWithLabel}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.Email")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="email"
                    type="text"
                    placeholder={t("CompanyMaster.Email")}
                    component={FormikInputWithLabel}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.CustomName")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="customName"
                    type="text"
                    placeholder={t("CompanyMaster.CustomName")}
                    component={FormikInputWithLabel}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.Address")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="address"
                    type="text"
                    placeholder={t("CompanyMaster.Address")}
                    multiline 
                    rows={3} 
                    component={CustomFormikTextField}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.LicenceNo")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="licenseNo"
                    type="text"
                    placeholder={t("CompanyMaster.LicenceNo")}
                    component={FormikInputWithLabel}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.VATNo")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="taxNo"
                    type="text"
                    placeholder={t("CompanyMaster.VATNo")}
                    component={FormikInputWithLabel}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                <label
                    style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '1.5',
                    letterSpacing: '0.00938em'
                    }}
                >
                  {t("CompanyMaster.CostingType")}
                </label>
                <Field
                    fullWidth
                    variant="outlined"
                    style={{
                    marginTop: '0.5rem',
                    border:
                      errors.costingType && touched.costingType
                      ? '1px solid red'
                      : ''
                    }}
                    value={values.costingType}
                    onChange={(e) => setFieldValue('costingType', e.target.value)}
                    defaultValue={'Select costingType'}
                    component={FormikSelect}
                    name="costingType"
                  >
                    <MenuItem value="AVG">{t("CompanyMaster.Average")}</MenuItem>
                    <MenuItem value="FIFO">{t("CompanyMaster.FIFO")}</MenuItem>
                </Field>
                {
                    <FormHelperText style={{ color: 'red' }}>
                    {errors.costingType && touched.costingType && errors.costingType}
                    </FormHelperText>
                }
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.TaxApplicable")}
                  </label>
                  <Field
                    fullWidth
                    variant="outlined"
                    style={{
                      marginTop: '0.5rem',
                      border:
                        errors.isTaxApplicable && touched.isTaxApplicable
                          ? '1px solid red'
                          : ''
                    }}
                    value={values.isTaxApplicable}
                    onChange={(e) => setFieldValue('isTaxApplicable', e.target.value)}
                    component={FormikSelect}
                    name="isTaxApplicable"
                  >
                    <MenuItem value='tax_registered'>{t("CompanyMaster.TaxRegistered")}</MenuItem>
                    <MenuItem value='tax_non_registered'>{t("CompanyMaster.TaxNonRegistered")}</MenuItem>
                  </Field>
                  {
                    <FormHelperText style={{ color: 'red' }}>
                      {errors.isTaxApplicable && touched.isTaxApplicable && errors.isTaxApplicable}
                    </FormHelperText>
                  }
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                     {t("CompanyMaster.Country")}
                  </label>
                  <Field
                    fullWidth
                    variant="outlined"
                    placeholder="Select Country"
                    style={{
                      marginTop: '0.5rem',
                      border:
                        errors.countryId && touched.countryId
                          ? '1px solid red'
                          : ''
                    }}
                    value={values.countryId}
                    onChange={(e) => setFieldValue('countryId', e.target.value)}
                    defaultValue={'Select country'}
                    component={FormikSelect}
                    name="countryId"
                  >
                    {countryList?.map((item, idx) => {
                      return (
                        <MenuItem key={idx} value={item.id}>
                          {item.countryName}
                        </MenuItem>
                      );
                    })}
                  </Field>
                  {
                    <FormHelperText style={{ color: 'red' }}>
                      {errors.countryId && touched.countryId && errors.countryId}
                    </FormHelperText>
                  }
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                     {t("CompanyMaster.Currency")}
                  </label>
                  <Field
                    fullWidth
                    variant="outlined"
                    placeholder="Select Currency"
                    style={{
                      marginTop: '0.5rem',
                      border:
                        errors.currencyId && touched.currencyId
                          ? '1px solid red'
                          : ''
                    }}
                    value={values.currencyId}
                    onChange={(e) => setFieldValue('currencyId', e.target.value)}
                    defaultValue={'Select currency'}
                    component={FormikSelect}
                    name="currencyId"
                  >
                    {currencyList?.map((item, idx) => {
                      return (
                        <MenuItem key={idx} value={item.id}>
                          {item.currencyName}
                        </MenuItem>
                      );
                    })}
                  </Field>
                  {
                    <FormHelperText style={{ color: 'red' }}>
                      {errors.currencyId && touched.currencyId && errors.currencyId}
                    </FormHelperText>
                  }
                </Grid>


                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.FinancialYearFrom")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="financialYearFrom"
                    type="date"
                    placeholder={t("CompanyMaster.FinancialYearFrom")}
                    component={FormikInputWithLabel}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.FinancialYearEnd")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="financialYearTo"
                    type="date"
                    placeholder={t("CompanyMaster.FinancialYearEnd")}
                    component={FormikInputWithLabel}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      letterSpacing: '0.00938em'
                    }}
                  >
                    {t("CompanyMaster.Website")}
                  </label>
                  <Field
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem'
                    }}
                    name="website"
                    type="text"
                    placeholder={t("CompanyMaster.Website")}
                    component={FormikInputWithLabel}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  position: 'fixed',
                  bottom: '0',
                  right: '0',
                  width: '40%',
                  //for mobile
                  '@media (max-width: 600px)': {
                    width: '100%'
                  },
                  //for tablet
                  '@media (min-width: 600px) and (max-width: 960px)': {
                    width: '70%'
                  },
                  backgroundColor: '#223354',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  zIndex: '5'
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={editLoading}
                  sx={{
                    backgroundColor: '#0071BC',
                    color: '#fff',
                    padding: '0.5rem 2rem',
                    margin: '1.1rem',
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    fontWeight: '700',
                    width: '30%',
                    marginLeft: '3rem',
                    borderRadius: '0.5rem',
                    zIndex: '2',
                    '&:disabled': {
                      opacity: 1, 
                      backgroundColor: 'rgba(74, 81, 94, 1)',
                      color: '#fff',
                    },
                  }}
                >
                  {editLoading ? t("Common.Saving") : t("Common.Update")} 
                </Button>
                <Button
                  onClick={() => {
                    setToggle(false);
                  }}
                  variant="contained"
                  sx={{
                    backgroundColor: 'rgba(74, 81, 94, 1)',
                    color: '#fff',
                    padding: '0.4rem 2rem',
                    margin: '1.1rem',
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    fontWeight: '700',
                    borderRadius: '0.5rem',
                    border: '1px solid #fff',
                    zIndex: '2'
                  }}
                >
                  {t("Common.Cancel")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </SwipeableDrawer>
  );
}

export default CompanyUpdateModal;
