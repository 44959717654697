import { useRef, useState } from 'react';
import ProfileModal from 'src/content/dashboards/Profile/Profile';
import {
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Notifications } from '@mui/icons-material';
import Reminder from 'src/content/dashboards/Reminders/Reminder';

const UserBoxButton = styled(Box)(() =>`
        cursor:pointer;
        backgroundColor:#F1F0E8;
        borderRadius:90%;
        justify-content:center;
        align-items:center;
        margin-left:12px;
`);

function HeaderUserbox() {

  const ref = useRef<any>(null);

  const [openProfile, setOpenProfile] = useState(false);
  const [toggleReminder, setToggleReminder] = useState(false);

  return (
    <>
      <UserBoxButton ref={ref} onClick={() => setToggleReminder(true)}>
        <Notifications sx={{ color: 'gray' }} />
      </UserBoxButton>
      <UserBoxButton ref={ref} onClick={() => setOpenProfile(true)}>
        <img width={41} height={41} style={{ borderRadius: '50%', border: '1px solid #DFDFDF' }} src='/static/user-icon.jpeg' />
      </UserBoxButton>
      {openProfile && <ProfileModal toggle={openProfile} setToggle={setOpenProfile} />}
      {toggleReminder && <Reminder toggle={toggleReminder} setToggle={setToggleReminder} />}
    </>
  );
};

export default HeaderUserbox;
