import { TextField } from "formik-mui";
import { styled } from "@mui/material";
import { memo } from "react";

const FormikInputWithLabel = styled(TextField)({
  "& .MuiInputLabel-root": {
    color: "#273045",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "0.5rem",
  },
  "& .MuiInputBase-root": {
    height: "37px",
    borderRadius: '6px',
    overFlowY:'auto',
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#273045",
      },
      "&:hover fieldset": {
        borderColor: "#273045",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#273045",
      },
    },
  },
});

export default memo(FormikInputWithLabel);
