import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, SwipeableDrawer, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Reminder({ toggle, setToggle }) {
  const theme = useTheme();

  return (
    <>
      {/* @ts-ignore */}
      <SwipeableDrawer
        ModalProps={{
          onBackdropClick: () => setToggle(false)
        }}
        anchor="right"
        open={toggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: '26%',
            zIndex: 9999,
            [theme.breakpoints.down('sm')]: {
              width: '100%'
            },
            //for mobile
            '@media (max-width: 600px)': {
              width: '100%'
            },
            //for tablet
            '@media (min-width: 600px) and (max-width: 960px)': {
              width: '70%'
            },
            borderRadius: '0 0 0 1rem',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            height: '100%',
            backgroundColor: '#fff'
          }
        }}
        onClose={() => setToggle(false)}
      >
        <Box sx={{ px: 3, py: 2  }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'top'
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Reminders</Typography>
            <CloseIcon sx={{ cursor: 'pointer', color: 'red' }} onClick={() => setToggle(false)}/>
          </Box>      
        </Box>
        <Divider />
        <Typography variant="h5" align='center' sx={{ fontWeight: 'bold',px: 3, py: 2.5 }}>
        Coming Soon ...
        </Typography>
      </SwipeableDrawer>
    </>
  );
}

export default React.memo(Reminder);
