//Current Date
const today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
const yyyy = today.getFullYear();
const currentDate = yyyy + '-' + mm + '-' + dd;

const getCurrentDate = () => {
  return currentDate;
};

//Round Off Function without round up
// const roundOff = (num: number, decimalPlaces: number): string => {
//   const p = Math.pow(10, decimalPlaces);
//   const roundedNumber = Math.trunc(num * p) / p;
//   const roundedString = roundedNumber.toFixed(decimalPlaces);
//   return roundedString;
// };

//Round Off Function with round up to the nearest number.
const roundOff = (num: number = 0, decimalPlaces: number = 2): string => {
  const p = Math.pow(10, decimalPlaces);
  const roundedNumber = Math.round((num) * p * (1 + 1e-15)) / p;
  const roundedString = roundedNumber.toFixed(decimalPlaces);
  return roundedString;
};

//Round Off Function with thousands separator.
const roundOffWithSeparator = (num: number = 0, decimalPlaces: number = 2): string => {
  const p = Math.pow(10, decimalPlaces);
  const roundedNumber = Math.round(num * p * (1 + 1e-15)) / p;
  return roundedNumber.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });
};

// Convert the date to ISO Format with time
function convertDateWithTime(inputDate: string) {
  // Check if the input date is already in ISO format
  if (!inputDate) return "0001-01-01T00:00:00.000+00:00";
    if (inputDate.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/)) {
      return inputDate;
    } else {
      // Try splitting the input date using "/" and "-"
      const parts = inputDate.split(/[/-]/);
      if (parts.length === 3) {
        const [firstPart, secondPart, thirdPart] = parts.map(Number);
        if (!isNaN(firstPart) && !isNaN(secondPart) && !isNaN(thirdPart)) {
          if (inputDate.includes("/")) {
            // If "/" is found, assume month/day/year format
            const currentDate = new Date();
            const isoDate = new Date(Date.UTC(thirdPart, firstPart - 1, secondPart, currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds())).toISOString();
            return isoDate;
          } else if (inputDate.includes("-")) {
            // If "-" is found, assume year-month-day format
            const currentDate = new Date();
            const isoDate = new Date(Date.UTC(firstPart, secondPart - 1, thirdPart, currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds())).toISOString();
            return isoDate;
          }
        }
      };
      return "0001-01-01T00:00:00.000+00:00";
  };
};

// Convert ISO date to date only
const convertISOToDate = (isoDate) => {
  if (!isoDate) return;
  // Extract only the date part (YYYY-MM-DD) from the ISO string;
  const datePart = isoDate.split('T')[0];
  return datePart;
};

const formattedISOToDate = (isoDate) => {
  if (!isoDate) return;
  
  // Extract the date part (YYYY-MM-DD) from the ISO string
  const [year, month, day] = isoDate.split('T')[0].split('-');
  
  // Return in DD-MM-YYYY format
  return `${day}-${month}-${year}`;
};

const get12HourTimeFromISO = (isoDateString) => {
  const date = new Date(isoDateString);
  const hours = date.getUTCHours(); // Use getUTCHours() to account for 'Z' (UTC time)
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 hour to 12 for 12 AM/PM format
  const formattedMinutes = minutes.toString().padStart(2, '0'); // Ensure minutes are always two digits

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

// Function to get the start date of any month of the current year
function getMonthStartDate(year, monthIndex) {
  return new Date(Date.UTC(year, monthIndex, 1)).toISOString().split('T')[0];
};

const base64ToFile = (base64, filename) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const downloadFile = (data, filename) => {
  // Convert binary data (Uint8Array or similar) to a Blob
  const blob = new Blob([data], { type: 'application/octet-stream' });

  // Create a link element
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename; // Specify the file name for the download

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the download by simulating a click
  link.click();

  // Clean up and remove the link
  document.body.removeChild(link);
};

const resizeImage = (file: File, MAX_WIDTH = 1500, MAX_HEIGHT = 1500): Promise<Blob> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		const reader = new FileReader();

		reader.onload = () => {
			img.src = reader.result as string;
		};

		img.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			if (ctx) {
				let { width, height } = img;

				if (width > MAX_WIDTH) {
					height *= MAX_WIDTH / width;
					width = MAX_WIDTH;
				}

				if (height > MAX_HEIGHT) {
					width *= MAX_HEIGHT / height;
					height = MAX_HEIGHT;
				}

				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, width, height);

				canvas.toBlob((blob) => {
					if (blob) {
						resolve(blob);
					} else {
						reject(new Error('Failed to resize image'));
					}
				}, 'image/png');
			} else {
				reject(new Error('Failed to get canvas context'));
			}
		};

		reader.onerror = () => reject(new Error('Failed to read file'));
		reader.readAsDataURL(file);
	});
};

const getPermissionStatus = (systemName = '', userRoleId = '', details = []) => {
  // If the user is an admin, immediately return true
  if (userRoleId === process.env.REACT_APP_ADMIN_ROLE_ID) {
    return true;
  }

  // Ensure details is an array, return false if not
  if (!Array.isArray(details)) return false;

  // Find the permission for the given systemName
  const permission = details?.find(item => item?.systemName === systemName);
  
  // Return the status if found, otherwise return false
  return permission ? permission.status : false;
};

export { getCurrentDate, roundOff, roundOffWithSeparator, convertDateWithTime, convertISOToDate, formattedISOToDate, getMonthStartDate, get12HourTimeFromISO, base64ToFile, resizeImage, getPermissionStatus, downloadFile };